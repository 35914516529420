import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TermsOfServiceToMfaEdgeGraphqlService } from '@app/registration/graph/edges/terms-of-service-to-mfa-edge-graphql.service';
import { RegistrationEdge } from '@app/registration/graph/graph-navigation.service';
import { MfaNode } from '@app/registration/graph/nodes/mfa.node';

@Injectable({
  providedIn: 'root',
})
export class TermsOfServiceToMfaEdge implements RegistrationEdge {
  constructor(private graphQLService: TermsOfServiceToMfaEdgeGraphqlService) { }

  nextNode = MfaNode;

  shouldNavigate$(): Observable<boolean> {
    return this.graphQLService
      .fetch()
      .pipe(
        map(
          response =>
            !response.data?.patient?.multiFactorAuthentication?.enabled && response.data.patient?.hasClaimedAccessKey,
        ),
      );
  }
}