import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { captureMessage } from '@sentry/angular-ivy';
import { map, Observable, take, tap } from 'rxjs';

import { State } from '@app/app.reducer';
import { AppointmentBookingState } from '@app/appointment/appointment-booking-state-service/appointment-booking-state';
import { AppointmentSearchState } from '@app/appointment/appointment-search-service/appointment-search-state';
import { PrimaryInsuranceAndMembershipIsDpcGraphQLService } from '@app/appointment/primary-insurance-and-membership-is-dpc-graphql.service';
import { AppointmentInventory } from '@app/appointment/provider-inventories';

import { AnalyticsService } from './analytics.service';
import { LaunchDarklyService } from './feature-flags/launchdarkly.service';
import { MP_EVENT_PAGE_VIEWED } from './mixpanel.constants';
import { MixpanelService } from './mixpanel.service';

export enum AppointmentAnalyticsProperty {
  AppointmentConfirmationPageModule = 'Appointment Confirmation Page',
  AppointmentConfirmationModal = 'Appointment Selected Modal',
  AppointmentSelectedPage = 'Appointment Selected Page',
  BehavioralHealthProgramIneligiblePageModule = 'Behavioral Health Program Ineligible Page',
  BookingFlow = 'Appointment Booking',
  BookingPageModule = 'Appointment Booking Page',
  InventoryPageModule = 'Appointment Inventory Page',
  ReasonForReschedulePageModule = 'Reason for Reschedule Page',
  RescheduleFlow = 'Appointment Reschedule',
  OMNowNavigationFlow = 'OM Now Navigation',
  VirtualOnlyLandingPageModule = 'Virtual Only Landing Page',
  GetVirtualCareCardSubmodule = 'Get Virtual Care Card',
  MessageProviderCardSubmodule = 'Message Provider Card',
  MindsetEducationPageModule = 'Enterprise Mindset Education Page',
  NextAvailableRemoteVisitSubmodule = 'Next Available Remote Visit Widget',
  GetCareCardSubmodule = 'Get Care Card',
  SelfPayConfirmationModule = 'Self Pay Confirmation Modal',
  ShiftEducationPageModule = 'Shift Education Page',
  MemberRequestFlow = 'Member Request',
  AppointmentsPageModule = 'Appointment Overview Page',
  SelectedAppointmentBookingModal = 'Selected Appointment Booking Modal',
  DeepLinkModule = 'Deep Link Page Source',
  AddInsurancePagePreBookingModalModule = 'Add Insurance Page Pre Booking Modal',
  AddInsurancePagePostBookingModalModule = 'Add Insurance Page Post Booking Modal',
  AddInsurancePageModalModule = 'Add Insurance Page Modal',
  UpdateInsurancePagePreBookingModalModule = 'Update Insurance Page Pre Booking Modal',
  UpdateInsurancePagePostBookingModalModule = 'Update Insurance Page Post Booking Modal',
  UpdateInsurancePageModalModule = 'Update Insurance Page Modal',
  PendingInsuranceModalModule = 'Pending Insurance Modal',
  AppointmentJoinModule = 'Appointment Join Page',
  InsuranceUpdateQuestionModule = 'Insurance Update Question Page',
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentAnalyticsBaseService extends AnalyticsService {
  protected bookingState: AppointmentBookingState;
  protected searchState: AppointmentSearchState;

  constructor(
    mixpanel: MixpanelService,
    store: Store<State>,
    launchDarkly: LaunchDarklyService,
    protected primaryInsuranceAndMembershipIsDpcGraphQLService: PrimaryInsuranceAndMembershipIsDpcGraphQLService,
  ) {
    super(mixpanel, store, launchDarkly);
  }

  setBookingState(bookingState: AppointmentBookingState) {
    this.bookingState = bookingState;
  }

  setSearchState(searchState: AppointmentSearchState) {
    this.searchState = searchState;
  }

  bookingCancelled() {
    captureMessage('AppointmentAnalyticsBaseService#bookingCancelled implemented in subclass');
  }

  rescheduled() {
    captureMessage('AppointmentAnalyticsBaseService#rescheduled implemented in subclass');
  }

  bookAppointmentClicked(_inventory: AppointmentInventory) {
    captureMessage('AppointmentAnalyticsBaseService#bookAppointmentClicked implemented in subclass');
  }

  appointmentBooked(_appointmentId: number, _inventoryId: number, _appointmentTypeId?: number) {
    captureMessage('AppointmentAnalyticsBaseService#appointmentBooked implemented in subclass');
  }

  bookingFailed() {
    return this.trackWithBookingAndSearchStates('Appointment Booking Failed');
  }

  inventorySelected(_inventory: AppointmentInventory, _props?: any) {
    captureMessage('AppointmentAnalyticsBaseService#inventorySelected Implemented in subclass');
  }

  seeMoreAppointmentsClicked(_inventoryCount: number, _props: any) {
    captureMessage('AppointmentAnalyticsBaseService#seeMoreAppointmentsClicked Implemented in subclass');
  }

  trackUpdateInsuranceModalOpened() {
    captureMessage('AppointmentAnalyticsBaseService#trackUpdateInsuranceModalOpened implemented in subclass');
  }

  trackAddInsuranceModalDismissedPreBooking() {
    captureMessage('AppointmentAnalyticsBaseService#trackAddInsuranceModalDismissedPreBooking implemented in subclass');
  }

  trackAddInsuranceModalDismissedPostBooking() {
    captureMessage(
      'AppointmentAnalyticsBaseService#trackAddInsuranceModalDismissedPostBooking implemented in subclass',
    );
  }

  trackAppointmentConfirmationModalViewed() {
    this.trackWithBookingAndSearchStates(MP_EVENT_PAGE_VIEWED, {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.AppointmentConfirmationModal,
      source: AppointmentAnalyticsProperty.InventoryPageModule,
    });
  }

  trackAppointmentSelectedPageViewed() {
    this.primaryInsuranceStatus$()
      .pipe(
        tap(primaryInsuranceStatus =>
          this.trackWithBookingAndSearchStates(MP_EVENT_PAGE_VIEWED, {
            flow: AppointmentAnalyticsProperty.BookingFlow,
            module: AppointmentAnalyticsProperty.AppointmentSelectedPage,
            source: AppointmentAnalyticsProperty.InventoryPageModule,
            insurance_status: primaryInsuranceStatus,
          }),
        ),
        take(1),
      )
      .subscribe();
  }

  trackWithBookingAndSearchStates(eventName: string, properties?: Record<string, any>): Observable<any> {
    const trackProps = {
      ...properties,
      ...this.bookingState?.trackableProperties,
      ...this.searchState?.trackableProperties,
    };

    return this.trackWithDefaultProperties(eventName, trackProps);
  }

  protected primaryInsuranceStatus$(): Observable<string | undefined> {
    return this.primaryInsuranceAndMembershipIsDpcGraphQLService
      .fetch({}, { fetchPolicy: 'network-only' })
      .pipe(map(result => result.data.patient?.primaryInsurance.verificationStatus));
  }
}
